















import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../../abstract/ui'
import { ImageProps } from '../../../../dsl/atoms/Image'
import { toImageProps } from '../../../../front/shared/support'

import { UiMarkdown } from '../../../partials/UiMarkdown'

import { CTABoxModule } from '../../CTABox.contracts'

@Component({ name: 'CTABoxModuleUi', components: { UiMarkdown } })
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  public get direction (): string {
    return this.data.content.direction
  }

  public get image (): ImageProps {
    if (!this.data.content.image) {
      throw new Error('CTABoxUi: Image is null!')
    }

    return toImageProps(this.data.content.image)
  }

  public get hasActionLink (): boolean {
    if (typeof this.data.content.cta === 'undefined') {
      return false
    }

    return typeof this.data.content.cta.action !== 'undefined' && ('target' in this.data.content.cta.action)
  }

  public get hasHeadline (): boolean {
    return !!this.data.content.headline
  }

  public get hasImage (): boolean {
    return !!this.data.content.image
  }

  public get hasContent (): boolean {
    return !!this.data.content.content
  }
}
export default CTABoxModuleUi
